import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './App.css'
import { AppModal } from './components/AppModal'
import { AppSnackbar } from './components/AppSnackbar'

import { isMode, withCtx } from './utils/helper'
import { AppCtx } from './constant/contexts'
import { Query, QueryClient, QueryClientProvider } from 'react-query'
import { useSnackbar } from './utils/custom-hook'
import { ReactQueryDevtools } from 'react-query-devtools'
import { useTranslation } from 'react-i18next'
import { AnyObject } from 'final-form'
import tracking from './utils/tracking'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { hydrate, dehydrate } from 'react-query/hydration'
import { some } from 'lodash'
import { OVERALL } from './services/overall/overall-query'
import { CERTIFICATES, USERS } from './services/user/user-query'
import { CURRENT_USER } from './services/auth/auth-query'
import { IDP_PLANNINGS } from './services/idp-planning/idp-planning-query'
import { TRAINING_RECORDS } from './services/training-record/training-record-query'
import { TRAINING_RECORD_USERS } from './services/training-record-user/training-record-user-query'
import { CERTIFICATE_USER } from './services/certificate-user/certificate-user-query'
import { MY_CAPABILITY_URL } from './services/my-capability/my-capability-query'

const AppRoute = React.lazy(() => import('./routes'))

const queryClient = new QueryClient()
export const dehydratedStateKey = 'dehydratedState'
const dehydratedStateString = localStorage.getItem(dehydratedStateKey)
if (dehydratedStateString) {
  const dehydratedState = JSON.parse(dehydratedStateString)
  hydrate(queryClient, dehydratedState, {
    defaultOptions: {
      queries: {
        cacheTime: 30 * 1000,
      },
    },
  })
  queryClient.invalidateQueries()
}

export const useOnError = () => {
  const { snackbar } = useSnackbar()
  const { t } = useTranslation('backend')
  return useCallback(
    (e: any) => {
      const { message = 'Not Identify', meta = {} } =
        e || ({} as { message: any; meta: { param?: AnyObject } })
      const { param = {} } = meta
      snackbar({
        type: 'error',
        message: t(message, { ...param, defaultValue: message }),
      })
    },
    [snackbar, t],
  )
}

export const App: React.FC<{ id: string }> = () => {
  const { pathname } = useLocation()
  const onError = useOnError()
  ChartJS.register(ArcElement, Tooltip, Legend)
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    tracking.page(pathname)
  }, [pathname])

  useLayoutEffect(() => {
    // set react query default options here
    queryClient.setDefaultOptions({
      queries: {
        onError,
        retry: 0,
        cacheTime: 1000 * 60 * 5, // 5 minute
        staleTime: 1000 * 60 * 5, // 5 minute
        onSettled: () => {
          const dehydratedState = dehydrate(queryClient, {
            shouldDehydrateQuery: (query: Query) => {
              const { queryKey } = query
              if (
                some(
                  [
                    OVERALL,
                    USERS,
                    CURRENT_USER,
                    IDP_PLANNINGS,
                    TRAINING_RECORDS,
                    TRAINING_RECORD_USERS,
                    CERTIFICATES,
                    CERTIFICATE_USER,
                    MY_CAPABILITY_URL,
                  ],
                  (key) => queryKey.includes(key),
                )
              ) {
                return true
              }
              return false
            },
            dehydrateQueries: true,
            dehydrateMutations: false,
          })
          localStorage.setItem(dehydratedStateKey, JSON.stringify(dehydratedState))
        },
      },
      mutations: {
        onError,
        retry: 0,
      },
    })
  }, [onError])

  return (
    <QueryClientProvider client={queryClient}>
      <AppModal />
      <AppSnackbar />
      <React.Suspense fallback={<div>Loading...</div>}>
        <AppRoute />
      </React.Suspense>

      {isMode('development') && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default withCtx(AppCtx)(App)
