import styled from '@emotion/styled'
import React, { CSSProperties, ReactNode } from 'react'
import { BLACK_000000 } from '../../constant/colors'
import { makeStyles } from '@material-ui/core'

const StyledText = styled.div<{
  weight: number | string
  size: number
  color: string
}>`
  font-family: 'Kanit', sans-serif;
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size / 16}rem;
  color: ${({ color }) => color};
  display: flex;
`

const useViewModeStyle = makeStyles({
  text: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
})

export type TextProps = {
  children?: ReactNode
  size?: number
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'bold' | 'normal'
  className?: string
  color?: string
  style?: CSSProperties
  wrap?: boolean
}

const Text = (props: TextProps) => {
  const classes = useViewModeStyle()
  const {
    children,
    size = 17,
    weight = 400,
    className,
    color = BLACK_000000,
    style,
    wrap = true,
    ...restProps
  } = props
  return (
    <StyledText
      size={size}
      weight={weight}
      className={wrap ? `${classes.text} ${className}` : className}
      color={color}
      style={style}
      {...restProps}
    >
      {children}
    </StyledText>
  )
}

export default Text
