import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import { GetIdpPlanningParam, UserWithIdpPlaningResListRes } from './manage-idp-planning-type'

export const MANAGE_IDP_PLANNING_URL = 'manage-idp-plannings'

export const useIdpPlanningList = (params?: GetIdpPlanningParam) => {
  return useQuery(
    [MANAGE_IDP_PLANNING_URL, 'users', params],
    async () => {
      const res = await api.blcpIdp.get<UserWithIdpPlaningResListRes>(
        `${MANAGE_IDP_PLANNING_URL}/users`,
        params,
      )
      return res.data
    },
    {
      enabled: !!params?.templateId,
      keepPreviousData: true,
      structuralSharing: false,
    },
  )
}
