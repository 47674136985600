import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { useSnackbar } from '../../utils/custom-hook'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { IDP_PLANNINGS } from '../idp-planning/idp-planning-query'
import { OVERALL } from '../overall/overall-query'
import {
  GetCoursesUserHistoryProps,
  UpdateCoursesUserHistoryProps,
  DeleteCoursesUserHistoryProps,
  GetCoursesUserParams,
  GetCoursesUserResponse,
  CoursesUserResponseType,
} from './course-user-type'

export const COURSES_USER = 'courses-user'
export const UPDATE_COURSES_USER_HISTORY = `${COURSES_USER}/history`

export const useCreateCoursesUserHistory = (coursesUserId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (param: any) => {
      trackingEvent(Category.MyIdp, 'Add Course progress')
      const { data } = await api.blcpIdp.post(UPDATE_COURSES_USER_HISTORY, param, {
        contentType: ContentType.FORMDATA,
      })
      return data
    },
    {
      onSuccess: () => {
        // console.log('Create Success')
        queryClient.invalidateQueries([COURSES_USER, coursesUserId, 'history'])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
  return mutate
}

export const useGetCoursesUserHistory = (props: GetCoursesUserHistoryProps) => {
  const { id } = props
  return useQuery(
    [COURSES_USER, id, 'history'],
    async () => {
      const { data }: any = await api.blcpIdp.get(`${COURSES_USER}/${id}/history`)
      return data.coursesUserHistories
    },
    {
      enabled: !!id,
    },
  )
}

export const useUpdateCoursesUserHistory = (coursesUserId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (props: UpdateCoursesUserHistoryProps) => {
      trackingEvent(Category.MyIdp, 'Update Course progress')
      const { param, coursesUserHistoryId } = props
      const { data } = await api.blcpIdp.patch(
        `${COURSES_USER}/history/${coursesUserHistoryId}`,
        param,
      )
      return data
    },
    {
      onSuccess: () => {
        // console.log('Update Success')
        queryClient.invalidateQueries([COURSES_USER, coursesUserId, 'history'])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
  return mutate
}

export const useDeleteCoursesUserHistory = (coursesUserId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (props: DeleteCoursesUserHistoryProps) => {
      trackingEvent(Category.MyIdp, 'Delete Course progress')
      const { coursesUserHistoryId } = props
      const res = await api.blcpIdp.delete(`${COURSES_USER}/history/${coursesUserHistoryId}`)
      return res
    },
    {
      onSuccess: () => {
        // console.log('Delete Success')
        queryClient.invalidateQueries([COURSES_USER, coursesUserId, 'history'])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )

  return mutate
}

export const useGetCoursesUsers = (params: GetCoursesUserParams) => {
  return useQuery([COURSES_USER, params], async () => {
    const { data } = await api.blcpIdp.get<GetCoursesUserResponse>(COURSES_USER, params)
    return data
  })
}

export const useGetCoursesUser = (params: GetCoursesUserHistoryProps) => {
  const { id } = params
  return useQuery(
    [COURSES_USER, 'coursesUser', id],
    async () => {
      const { data } = await api.blcpIdp.get<CoursesUserResponseType>(`${COURSES_USER}/${id}`)
      return data.coursesUser
    },
    {
      enabled: !!id,
    },
  )
}
