import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isEqual } from 'lodash'
import { useMutation, UseQueryOptions, useQuery } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { downloadFile } from '../../utils/helper'
import { AttachmentResponse } from './attachment-type'

export const useUploadFile = (axiosOption?: AxiosRequestConfig) => {
  return useMutation(async (data: File[]) => {
    const response = await api.blcpIdp.post<AttachmentResponse>(
      'attachments/upload',
      { files: data },
      { contentType: ContentType.FORMDATA, ...axiosOption },
    )
    return response.data
  })
}

export const useImage = (
  attachmentId?: string,
  option?: UseQueryOptions<AxiosResponse<Blob> | undefined>,
) => {
  const { data: res, ...restQuery } = useFileWithResponse(attachmentId, option)
  return {
    data: res?.data,
    ...restQuery,
  }
}

export const useFileWithResponse = (
  attachmentId?: string,
  option?: UseQueryOptions<AxiosResponse<Blob> | undefined>,
) => {
  return useQuery<AxiosResponse<Blob> | undefined>(
    ['attachments', attachmentId],
    async () => {
      if (attachmentId) {
        const res = await api.blcpIdp.getFile<Blob>(`attachments/${attachmentId}`)
        return res
      }
      return undefined
    },
    {
      // enabled: !!attachmentId,
      structuralSharing: false,
      isDataEqual: (ownData, newData) =>
        isEqual(ownData?.data.arrayBuffer, newData?.data.arrayBuffer),
      ...option,
    },
  )
}

export const useDownloadFile = () => {
  return useMutation(
    async (attachmentId: string) => {
      const res = await api.blcpIdp.getFile(`attachments/${attachmentId}`)
      // console.log('resss', res)
      downloadFile(res)
    },
    {
      onSuccess: () => {
        // console.log('DownloadFile')
      },
    },
  )
}
