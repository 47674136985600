import { AppModalProps } from '../components/AppModal'
import { AppSnackbarProps } from '../components/AppSnackbar'
import { createCtx } from '../utils/helper'
import {} from '../components/layout'

const AppModalOption: AppModalProps = {
  visible: false,
  message: '',
  children: undefined,
}

const AppSnackbarOption: AppSnackbarProps = {
  visible: false,
  message: '',
  description: '',
  type: 'info',
}

export const AppCtx = createCtx({
  appModal: AppModalOption,
  appSnackbar: AppSnackbarOption,
  topbarTab: {
    visibled: false,
    tabs: [{ label: '', path: '' }],
    onOk: null,
    onCancel: null,
    title: '',
    message: '',
    children: null,
    closeOnClickOutside: true,
    closeIcon: false,
    style: {},
  },
})
