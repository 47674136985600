import dayjs from 'dayjs'
import { random, range, sample } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { CompetencyFieldValue } from '../../components/fields/CompetencyField'
import { api } from '../../utils/api'
import { useSnackbar } from '../../utils/custom-hook'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { USERS } from '../auth/auth-query'
import { IdpPlanning } from '../entity-typed'
import { CompentencyStatus } from '../enum-typed'
import { IDP_PLANNINGS_APPROVER } from '../idp-planning-approver/idp-planning-query'
import { MANAGE_IDP_PLANNING_URL } from '../manage-idp-planning/manage-idp-planning-query'
import { NOTIFICATIONCOUNT_URL, NOTIFICATION_URL } from '../notification/notification-query'
import { OVERALL } from '../overall/overall-query'
import {
  ApproveAndEditIdpPlanningParams,
  ApproveIdpPlanningParams,
  CreateIdpPlanProps,
  GetActionResponse,
  GetAdminActionParams,
  GetHeadActionParams,
  GetMandatoryTraningParams,
  GetMandatoryTraningResponse,
  GetMyIdpResponseType,
  GetUserActionParams,
  IdpProcessOptionRes,
  RejectIdpPlanningParams,
  SubmitMyIdpParams,
} from './idp-planning-type'

export const IDP_PLANNINGS = 'idp-plannings'

export const useIdpPlanning = (idpPlanningId?: string) => {
  return useQuery(
    [IDP_PLANNINGS, idpPlanningId],
    async () => {
      const res = await api.blcpIdp.get<{ idpPlanning: IdpPlanning }>(
        `${IDP_PLANNINGS}/${idpPlanningId}`,
      )
      return res.data.idpPlanning
    },
    {
      enabled: !!idpPlanningId,
    },
  )
}

export const useIdpProcessOption = (idpPlanningId?: string) => {
  return useQuery(
    [IDP_PLANNINGS, idpPlanningId, 'idp-process-options'],
    async () => {
      const res = await api.blcpIdp.get<IdpProcessOptionRes>(
        `${IDP_PLANNINGS}/${idpPlanningId}/idp-process-options`,
      )
      return res.data
    },
    {
      enabled: !!idpPlanningId,
    },
  )
}

export const useGetMandatoryTraning = (params: GetMandatoryTraningParams) => {
  const { userId, positionId, isMandatoryInIdpPlanning } = params
  const MANDATORY_TRANING = `${IDP_PLANNINGS}/mandatory-traning`
  return useQuery(
    [IDP_PLANNINGS, 'mandatory-traning', params],
    async () => {
      const { data } = await api.blcpIdp.get<{ mandatories: GetMandatoryTraningResponse[] }>(
        MANDATORY_TRANING,
        {
          userId,
          positionId,
          isMandatoryInIdpPlanning,
        },
      )
      return data.mandatories
    },
    {
      enabled: !!positionId && !!userId,
    },
  )
}

export const useGetIdpPlanning = (
  userId: string,
  option?: UseQueryOptions<GetMyIdpResponseType['idpPlanning'], string>,
) => {
  return useQuery<GetMyIdpResponseType['idpPlanning'], string>(
    [IDP_PLANNINGS, { userId }],
    async () => {
      const { data } = await api.blcpIdp.get<GetMyIdpResponseType>(
        `${USERS}/${userId}/idp-planning`,
      )
      return data.idpPlanning
    },
    {
      enabled: !!userId,
      ...option,
    },
  )
}

const initialAction: GetActionResponse = {
  canApprove: false,
  canDraft: false,
  canEdit: false,
  canRate: false,
  canReject: false,
  canSend: false,
  canUpdate: false,
  canManagerRate: false,
}

export const useGetUserAction = (params: GetUserActionParams) => {
  const { idpStatus = 'new' } = params
  const USER_ACTION = `${IDP_PLANNINGS}/user-action`
  return useQuery<GetActionResponse>(
    [IDP_PLANNINGS, 'user-action', params],
    async () => {
      const { data } = await api.blcpIdp.get<GetActionResponse>(USER_ACTION, {
        idpStatus,
      })
      return data
    },
    {
      initialData: initialAction,
      initialDataUpdatedAt: 0,
    },
  )
}

export const useGetHeadAction = (params: GetHeadActionParams) => {
  const { idpStatus = 'new', idpPlanningId } = params
  const HEAD_ACTION = `${IDP_PLANNINGS}/head-action`
  return useQuery<GetActionResponse>(
    [IDP_PLANNINGS, 'head-action', params],
    async () => {
      const { data } = await api.blcpIdp.get<GetActionResponse>(HEAD_ACTION, {
        idpStatus,
        idpPlanningId,
      })
      return data
    },
    {
      initialData: initialAction,
      initialDataUpdatedAt: 0,
    },
  )
}

export const useGetAdminAction = (params: GetAdminActionParams) => {
  const { idpStatus = 'new' } = params
  const ADMIN_ACTION = `${IDP_PLANNINGS}/admin-action`
  return useQuery<GetActionResponse>(
    [IDP_PLANNINGS, 'admin-action', params],
    async () => {
      const { data } = await api.blcpIdp.get<GetActionResponse>(ADMIN_ACTION, {
        idpStatus,
      })
      return data
    },
    {
      initialData: initialAction,
      initialDataUpdatedAt: 0,
    },
  )
}

// mutation function
export const useSubmitMyIdp = () => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    async (params: SubmitMyIdpParams) => {
      if (params.idpStatus === 'new') {
        trackingEvent(Category.MyIdp, 'Save Draft My IDP Planning')
      } else {
        trackingEvent(Category.MyIdp, 'Save My IDP Planning')
      }
      const { data } = await api.blcpIdp.post(IDP_PLANNINGS, params)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

export const useCreateAspiration = (props: CreateIdpPlanProps) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  const mutate = useMutation(
    async (param: any) => {
      trackingEvent(Category.MyAspiration, 'Save My Aspiration')
      const { data } = await api.blcpIdp.post(`${IDP_PLANNINGS}/aspiration`, param)
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
  return mutate
}

export const useIdpPlanningBackward = () => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(
    ({ idpPlanningId, toState }: { idpPlanningId: string; toState: string }) => {
      trackingEvent(Category.Admin, 'Backward IDP Planning')
      return api.blcpIdp.patch(`${IDP_PLANNINGS}/${idpPlanningId}/backward-planning`, { toState })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

export const useIdpReviewBackward = () => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(
    ({ idpPlanningId, toState }: { idpPlanningId: string; toState: string }) => {
      trackingEvent(Category.Admin, 'Backward IDP Review')
      return api.blcpIdp.patch(`${IDP_PLANNINGS}/${idpPlanningId}/backward-review`, { toState })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

export const useApproveIdpPlanning = (approverId?: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    async (params: ApproveIdpPlanningParams) => {
      trackingEvent(Category.MyTeam, 'Approve IDP Planning from team list page')
      const { idpPlanningId } = params
      if (!approverId || !idpPlanningId) {
        return
      }
      const { data } = await api.blcpIdp.patch(`${IDP_PLANNINGS}/${idpPlanningId}/approved`, {
        approverId,
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

export const useRejectedIdpPlanning = (rejectorId?: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    async (params: RejectIdpPlanningParams) => {
      trackingEvent(Category.MyTeam, 'Reject IDP Planning')
      const { idpPlanningId, remark } = params
      if (!rejectorId || !idpPlanningId) {
        return
      }
      const { data } = await api.blcpIdp.patch(`${IDP_PLANNINGS}/${idpPlanningId}/rejected`, {
        rejectorId,
        remark,
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

export const useApproveAndEditIdpPlanning = (approverId?: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    async (params: ApproveAndEditIdpPlanningParams) => {
      trackingEvent(Category.MyTeam, 'Approve IDP Planning')
      const { idpPlanningId, currentPosition, aspirationPosition, specialPosition } = params
      if (!approverId || !idpPlanningId) {
        return
      }
      const { data } = await api.blcpIdp.patch(
        `${IDP_PLANNINGS}/${idpPlanningId}/approved-and-update`,
        {
          approverId,
          currentPosition,
          specialPosition,
          aspirationPosition,
        },
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
        queryClient.invalidateQueries([MANAGE_IDP_PLANNING_URL])
        queryClient.invalidateQueries([IDP_PLANNINGS_APPROVER])
        queryClient.invalidateQueries([OVERALL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}

// mock data for example
const idpStatusList: CompentencyStatus[] = ['not-start', 'complete', 'in-progress']

const mockData = (): Partial<CompetencyFieldValue> => {
  const status = sample(idpStatusList) || idpStatusList[0]
  return {
    completeDate: dayjs().subtract(random(9000000), 'minute').format(),
    status,
  }
}
const mockDataList = (size: number) => range(size).map(mockData)

export const useGetCompetency = (userId: string, positionId: string) => {
  return useQuery(['GET Competency', { userId, positionId }], () => {
    const initialValues: Partial<any> = {
      current: mockDataList(1),
      aspired: [],
      aspiredDelete: [],
      currentDelete: [],
    }
    return Promise.resolve(initialValues)
  })
}
