import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { api } from '../../utils/api'
import {
  GetCertificateUsersParams,
  GetCertificateUsersResponse,
  GetMyTeamResponse,
  GetUsersParams,
  GetUsersResponse,
  MyTeamIdpStatusFilter,
  UserIdpPlanningRes,
} from './user-type'

export const USERS = 'users'
export const CERTIFICATES = 'certificates'

export const useGetUsers = (params: GetUsersParams, option?: UseQueryOptions<GetUsersResponse>) => {
  return useQuery(
    [USERS, params],
    async () => {
      const { data } = await api.blcpIdp.get<GetUsersResponse>(USERS, params)
      return data
    },
    option,
  )
}

export const useGetAsyncUserOptions = <T extends BaseOptionType = BaseOptionType>() => {
  return useMutation([USERS], async (params: GetUsersParams) => {
    const { q } = params
    if (!q) return Promise.resolve([])
    const { data } = await api.blcpIdp.get<GetUsersResponse>(USERS, params)
    const options = data.items.map((u) => {
      return { label: `${u.firstname} ${u.lastname}`, value: u.id } as T
    })
    return options
  })
}

export const useGetUserOption = () => {
  const { data: users } = useGetUsers({})
  const { data: allUsers, ...restQuery } = useGetUsers({ limit: users?.meta.totalItems })
  const data = allUsers?.items.map((user) => ({
    value: user.id,
    label: `${user.firstname} ${user.lastname}`,
  }))
  return {
    data,
    ...restQuery,
  }
}

export const useUserIdpPlanning = (userId: string) => {
  return useQuery(
    [USERS, userId, 'idp-planning'],
    async () => {
      const res = await api.blcpIdp.get<{ idpPlanning: UserIdpPlanningRes }>(
        `${USERS}/${userId}/idp-planning`,
      )
      return res.data.idpPlanning
    },
    {
      enabled: !!userId,
    },
  )
}

export const useGetMyTeam = (
  userId: string,
  params?: {
    page?: number
    limit?: number
    q?: string
    idpStatus?: MyTeamIdpStatusFilter[]
  },
) => {
  const { idpStatus, limit = 5, page = 1, q } = params || {}
  const GET_MY_TEAM = `${USERS}/${userId}/my-team`
  return useQuery(
    [USERS, userId, 'my-team', params],
    async () => {
      const { data } = await api.blcpIdp.get<GetMyTeamResponse>(GET_MY_TEAM, {
        idpStatus,
        limit,
        page,
        q,
      })
      return data
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!userId,
      staleTime: 60 * 1000,
    },
  )
}

export const useHasMyTeam = (userId?: string) => {
  return useQuery(
    [USERS, userId, 'has-my-team'],
    async () => {
      const { data } = await api.blcpIdp.get<GetMyTeamResponse>(`${USERS}/${userId}/my-team`)
      const hasMyTeam = !!data.items.length
      return hasMyTeam
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
      staleTime: 60 * 1000,
    },
  )
}

export const useGetMyTeamInfinite = (
  userId: string,
  params?: {
    page?: number
    limit?: number
    q?: string
    idpStatus?: MyTeamIdpStatusFilter[]
  },
) => {
  const GET_MY_TEAM = `${USERS}/${userId}/my-team`
  const { idpStatus, limit = 5, page = 1, q } = params || {}
  return useInfiniteQuery(
    [USERS, userId, 'my-team-infinite', { limit, q, idpStatus }],
    async ({ pageParam = page }) => {
      const { data } = await api.blcpIdp.get<GetMyTeamResponse>(GET_MY_TEAM, {
        page: pageParam,
        q,
        limit,
        idpStatus,
      })
      return data
    },
    {
      getPreviousPageParam: ({ meta }) => (meta.currentPage > 1 ? meta.currentPage - 1 : null),
      getNextPageParam: ({ meta }) =>
        meta.currentPage < meta.totalPages ? meta.currentPage + 1 : null,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!userId,
      staleTime: 60 * 1000,
    },
  )
}
const emptyResponse: GetCertificateUsersResponse = {
  items: [],
  meta: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 5,
    totalItems: 0,
    totalPages: 1,
  },
}

export const useGetUserCertificates = (
  params: GetCertificateUsersParams,
  option?: UseQueryOptions<GetCertificateUsersResponse>,
) => {
  const { userId, templateId, page, limit, q } = params
  return useQuery(
    [USERS, userId, CERTIFICATES, params],
    async () => {
      if (!userId || !templateId) return Promise.resolve(emptyResponse)
      const { data } = await api.blcpIdp.get<GetCertificateUsersResponse>(
        `${USERS}/${userId}/${CERTIFICATES}`,
        {
          templateId,
          page,
          limit,
          q,
        },
      )
      return data
    },
    {
      // enabled: !!params.templateId && !!params.userId,
      keepPreviousData: true,
      ...option,
    },
  )
}

export const useGetUserCertificatesInfinite = (
  params: GetCertificateUsersParams,
  option?: UseInfiniteQueryOptions<GetCertificateUsersResponse>,
) => {
  const { userId, templateId, page = 1, limit = 5, q } = params
  return useInfiniteQuery(
    [USERS, userId, CERTIFICATES, 'infinite', params],
    async ({ pageParam = page }) => {
      if (!userId || !templateId) return Promise.resolve(emptyResponse)
      const { data } = await api.blcpIdp.get<GetCertificateUsersResponse>(
        `${USERS}/${userId}/${CERTIFICATES}`,
        {
          q,
          templateId,
          page: pageParam,
          limit,
        },
      )
      return data
    },
    {
      getNextPageParam: ({ meta }) => {
        return meta.currentPage < meta.totalPages ? meta.currentPage + 1 : null
      },
      refetchOnWindowFocus: false,
      ...option,
    },
  )
}
