import React, { useContext } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { AppCtx } from '../constant/contexts'
import MuiAlert from '@material-ui/lab/Alert'
import Text from './common/Text'
import { WHITE_FFFFFF } from '../constant/colors'

export type AppSnackbarProps = {
  visible: boolean
  message?: string
  description?: string
  type?: 'error' | 'info' | 'success' | 'warning'
}

export const AppSnackbar = () => {
  const [state, setState, { initialValue }] = useContext(AppCtx)

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setState({
      appSnackbar: initialValue.appSnackbar,
    })
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={state.appSnackbar.visible}
      autoHideDuration={30000}
      onClose={handleClose}
      style={{
        minWidth: '22rem',
      }}
      ContentProps={{
        style: {
          minWidth: '22rem',
        },
      }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={state.appSnackbar.type}
        variant="filled"
        style={{ minWidth: '22rem' }}
      >
        <Text color={WHITE_FFFFFF}>{state.appSnackbar.message}</Text>
        {/* <Text weight={200} size={12} color={WHITE_FFFFFF}>
          {state.appSnackbar.description}
        </Text> */}
      </MuiAlert>
    </Snackbar>
  )
}
