import { uniq } from 'lodash'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { api } from '../../utils/api'
import { getToken } from '../../utils/helper'
import { removeToken, setToken } from './auth-action'
import { CurrentUser, GetUserParams, SigninParams, SigninResponse } from './auth-types'
import tracking, { Category } from '../../utils/tracking'

export const AUTH = 'auth'
export const SIGN_IN = `${AUTH}/sign-in`

export const USERS = 'users'
export const CURRENT_USER = `${USERS}/current-user`

export const useCurrUser = () => {
  const clientClient = useQueryClient()
  return useQuery<CurrentUser | undefined>(
    [CURRENT_USER],
    async () => {
      const response = await api.blcpIdp.get<CurrentUser>(CURRENT_USER)
      return response.data
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!getToken(),
      onError: () => {
        removeToken()
        clientClient.setQueryData(CURRENT_USER, undefined)
      },
      suspense: true,
    },
  )
}

export const useUser = <T extends CurrentUser = CurrentUser>(
  params: GetUserParams,
  options?: UseQueryOptions<T>,
) => {
  const { userId, relations: dupRelations = [] } = params
  const relations = uniq(dupRelations)
  return useQuery<T>(
    [USERS, { userId, relations }],
    async () => {
      const response = await api.blcpIdp.get<T>(`${USERS}/${userId}`, { relations })
      return response.data
    },
    {
      retry: 0,
      enabled: !!userId,
      ...options,
    },
  )
}

export const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: SigninParams) => {
      const { data } = await api.blcpIdp.post<SigninResponse>(SIGN_IN, params)
      const { firstname, lastname } = data.user
      tracking.event(Category.Auth, 'Login', { firstname, lastname })
      return data
    },
    {
      onSuccess: (data) => {
        const { accessToken, user } = data
        setToken(accessToken)
        queryClient.setQueryData([CURRENT_USER], user)
      },
    },
  )
}

export const useLoginForTest = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: SigninParams) => {
      const { data } = await api.blcpIdp.post<SigninResponse>(`${SIGN_IN}/email`, params)
      const { firstname, lastname } = data.user
      tracking.event(Category.Auth, 'Debug Login', { firstname, lastname })
      return data
    },
    {
      onSuccess: (data) => {
        const { accessToken, user } = data
        setToken(accessToken)
        queryClient.setQueryData([CURRENT_USER], user)
      },
    },
  )
}

export const useLogout = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      return Promise.resolve()
    },
    {
      onSuccess: () => {
        removeToken()
        queryClient.invalidateQueries([CURRENT_USER])
        queryClient.removeQueries()
      },
    },
  )
}
