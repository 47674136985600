import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { api } from '../../utils/api'
import { useSnackbar } from '../../utils/custom-hook'
import { Category, useTrackingEvent } from '../../utils/tracking'

import { COURSES_USER } from '../course-user/course-user-query'
import { IDP_PLANNINGS } from '../idp-planning/idp-planning-query'
import {
  AddDevelopMentActionProps,
  DeleteDevelopmentActionUserParams,
  GetDevelopmentActionUsersResponse,
  GetDevelopmentUserActionParams,
  GetIdpDevelopmentParams,
  GetIdpDevelopmentRecordParams,
  GetMyIdpResponseTypeCapability,
  GetTemplateAndCoursesResponse,
} from './my-capability-types'

export const MY_CAPABILITY_URL = 'idp-capability'
export const IDP_COMPETENCY = `${MY_CAPABILITY_URL}/idp-competency`

export const useGetIdpDevelopmentRecord = (
  param: GetIdpDevelopmentRecordParams,
  option?: UseQueryOptions<GetTemplateAndCoursesResponse['templates']>,
) => {
  const { userId, yearStart, yearEnd, status } = param
  return useQuery(
    [MY_CAPABILITY_URL, IDP_COMPETENCY, { userId, yearStart, yearEnd, status }],
    async () => {
      const { data } = await api.blcpIdp.get<GetTemplateAndCoursesResponse>(IDP_COMPETENCY, {
        userId,
        yearStart,
        yearEnd,
        status,
      })
      return data.templates
    },
    {
      enabled: !!userId,
      ...option,
    },
  )
}

export const useGetIdpDevelopment = (param: GetIdpDevelopmentParams) => {
  const { userId, templateId } = param
  return useQuery(
    [MY_CAPABILITY_URL, { userId, templateId }],
    async () => {
      const { data } = await api.blcpIdp.get<GetMyIdpResponseTypeCapability>(
        `${MY_CAPABILITY_URL}/overall-result`,
        { userId, templateId },
      )
      return data
    },
    {
      enabled: !!userId,
    },
  )
}

export const useAddDevelopMentAction = () => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (param: AddDevelopMentActionProps) => {
      trackingEvent(Category.MyCapability, 'Add Development Action')
      const { data } = await api.blcpIdp.post<GetDevelopmentActionUsersResponse>(
        `${MY_CAPABILITY_URL}/development-action-user`,
        param,
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSES_USER])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([MY_CAPABILITY_URL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
  return mutate
}

export const useUpdateDevelopMentAction = (developmentCourseId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutate = useMutation(
    async (param: AddDevelopMentActionProps) => {
      trackingEvent(Category.MyCapability, 'Update Development Action')
      const { data } = await api.blcpIdp.put(
        `${MY_CAPABILITY_URL}/development-action-user/${developmentCourseId}`,
        param,
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSES_USER])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([MY_CAPABILITY_URL])
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
  return mutate
}

export const useGetDevelopmentActionUser = (params: GetDevelopmentUserActionParams) => {
  const { developmentActionUserId } = params
  return useQuery(
    [MY_CAPABILITY_URL, params],
    async () => {
      const { data } = await api.blcpIdp.get<GetDevelopmentActionUsersResponse>(
        `${MY_CAPABILITY_URL}/development-action-user/${developmentActionUserId}`,
      )
      return data.developmentActionUser
    },
    {
      enabled: !!developmentActionUserId,
    },
  )
}

export const useDeleteDevelopmentActionUser = () => {
  const { t } = useTranslation()
  const { snackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(
    (params: DeleteDevelopmentActionUserParams) => {
      const { developmentActionUserId } = params
      return api.blcpIdp.delete<GetDevelopmentActionUsersResponse>(
        `${MY_CAPABILITY_URL}/development-action-user/${developmentActionUserId}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COURSES_USER])
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([MY_CAPABILITY_URL])
        snackbar({ type: 'success', message: t('status.success') })
      },
    },
  )
}
