export const WHITE_F8F8F8 = '#F8F8F8'
export const WHITE_FFFFFF = '#FFFFFF'
export const BLUE_004D99 = '#004D99'
export const BLUE_505E6C = '#505E6C'
export const GRAY_505E6C = '#505E6C'
export const GREEN_AFBD00 = '#AFBD00'
export const GREEN_0BAC0B = '#0BAC0B'
export const RED_FF0000 = '#FF0000'
export const BLACK_000000 = '#000000'
export const GRAY_171717 = '#171717'
export const GRAY_707070 = '#707070'
export const GRAY_222222 = '#222222'
export const GRAY_6E6E6E = '#6E6E6E'
export const GRAY_6A6A6A = '#6A6A6A'
export const GRAY_B4B4B4 = '#B4B4B4'
export const GRAY_ADADAD = '#ADADAD'
export const GRAY_E4E4E4 = '#E4E4E4'
export const GRAY_C1C1C1 = '#C1C1C1'
export const GRAY_E8E8E8 = '#E8E8E8'
export const GRAY_D7D7D7 = '#D7D7D7'
export const BLACK_00000D = '#00000D'
export const BLACK_171717 = '#171717'
export const GREEN_00A800 = '#00A800'
export const GREEN_D8F2DB = '#D8F2DB'
export const RED_FFD0D0 = '#FFD0D0'
export const ORANGE_FF6F00 = '#FF6F00'
export const ORANGE_FFE9D0 = '#FFE9D0'
export const GREEN_0EB700 = '#0EB700'
export const RED_DD1212 = '#DD1212'
export const GRAY_F5F5F5 = '#F5F5F5'
export const GRAY_C7C7C7 = '#C7C7C7'
export const GRAY_AFAFAF = '#AFAFAF'
export const GRAY_F7F7F7 = '#F7F7F7'
export const GRAY_F5F6F7 = '#F5F6F7'
export const GRAY_D9DBDE = '#D9DBDE'
export const GRAY_F8F8F8 = '#F8F8F8'
export const GRAY_0000008A = '#0000008A'
export const BLUE_919DAA3D = '#919DAA3D'
export const RED_C10F0F = '#C10F0F'
export const RED_FFF0EB = `#FFF0EB`
export const RED_F94741 = `#f94741`
export const GRAY_F4F5F6 = '#F4F5F6'
export const GRAY_989898 = '#989898'
export const YELLOW_FFAE14 = '#FFAE14'
export const GRAY_525252 = `#525252`
export const GRAY_B9B9B9 = `#b9b9b9`
export const RED_F43A34 = `#F43A34`
export const GRAY_BEBEBE = `#BEBEBE`
export const BLUE_784AF4 = `#784af4`
export const GRAY_EAEAF0 = `#eaeaf0`
export const BLUE_1592E6 = `#1592E6`
export const YELLOW_EAE6B7 = '#EAE6B7'
export const YELLOW_DFD883 = '#DFD883'
export const GREEN_A4DECA = '#A4DECA'
export const GREEN_B9E8D8 = '#B9E8D8'
export const GREEN_97DDC3 = '#97DDC3'
export const GREEN_6CC4A3 = '#6CC4A3'
export const RED_F1C3B6 = '#F1C3B6'
export const RED_FFC8C8 = '#FFC8C8'

export const GRAY_E3E3E3 = `rgba(23, 23, 23, 0.25)`

export const GREEN_00B050 = '#00B050'
export const YELLOW_FEE96C = '#FEE96C'
export const YELLOW_FFC000 = '#FFC000'
export const RED_EA5234 = '#EA5234'

export const GREY_17171760 = '#17171760'
