import { AxiosRequestConfig } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../utils/api'
import { useSnackbar } from '../../utils/custom-hook'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { useDownloadFile } from '../attachment/attachment-qeury'
import {
  GetDashboardOverallParams,
  GetDashboardOverallResponse,
  OverallFiltersValues,
  OverallReportResponseType,
} from './overall-type'

export const OVERALL = 'overall'
export const OVERALL_EXPORT = `${OVERALL}/export`
export const OVERALL_LIST = `${OVERALL}/list`

const parseSortByToBoolean = (value: SortBy): boolean | undefined => {
  switch (value) {
    case 'ASC':
      return true
    case 'DESC':
      return false
    default:
      return undefined
  }
}
export const useDownloadOverallReport = (option?: AxiosRequestConfig) => {
  const trackingEvent = useTrackingEvent()
  const { t } = useTranslation()
  const { snackbar } = useSnackbar()
  const { mutate: download } = useDownloadFile()
  return useMutation(
    async (params: OverallFiltersValues) => {
      trackingEvent(Category.Admin, 'Export Overall')
      const _params = {
        jobFunctionId: params.jobFunctionId,
        departmentId: params.departmentId,
        isEmployeeOrderAsc: parseSortByToBoolean(params.isEmployeeOrderAsc),
        isPositionOrderAsc: parseSortByToBoolean(params.isPositionOrderAsc),
        isSectionOrderAsc: parseSortByToBoolean(params.isSectionOrderAsc),
        isQualifiedPercentOrderAsc: parseSortByToBoolean(params.isQualifiedPercentOrderAsc),
        [`${params.filterType}`]: params.filterBy,
      }
      return await api.blcpIdp.post<OverallReportResponseType>(OVERALL_EXPORT, _params)
    },
    {
      onSuccess: (value) => {
        download(value.data.attachmentId)
        snackbar({ message: t('status.success'), type: 'success' })
      },
    },
  )
}
export const useGetCompetencyDashboardOverall = (params: GetDashboardOverallParams) => {
  const {
    jobFunctionId,
    departmentId,
    filterType,
    filterBy,
    isEmployeeOrderAsc,
    isPositionOrderAsc,
    isSectionOrderAsc,
    isQualifiedPercentOrderAsc,
  } = params
  return useQuery(
    [OVERALL, OVERALL_LIST, params],
    async () => {
      if (!jobFunctionId || !departmentId) {
        return Promise.reject()
      }
      const _params = {
        jobFunctionId,
        departmentId,
        isEmployeeOrderAsc: parseSortByToBoolean(isEmployeeOrderAsc),
        isPositionOrderAsc: parseSortByToBoolean(isPositionOrderAsc),
        isSectionOrderAsc: parseSortByToBoolean(isSectionOrderAsc),
        isQualifiedPercentOrderAsc: parseSortByToBoolean(isQualifiedPercentOrderAsc),
        [`${filterType}`]: filterBy,
      }
      const { data } = await api.blcpIdp.get<GetDashboardOverallResponse>(
        `${OVERALL_LIST}`,
        _params,
      )

      return data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      keepPreviousData: true,
      onError: () => {},
    },
  )
}

// const mockUser = (user: User, index: number): DashboardOverallInf => {
//   return {
//     rank: index + 1,
//     firstname: [user.firstname, user.lastname].join(' '),
//     lastname: user.lastname,
//     positionName: user.displayPosition,
//     department: user.displayDepartment,
//     section: user.section,
//     boiler: Math.floor(Math.random() * 100),
//     turbine: Math.floor(Math.random() * 100),
//     bop: Math.floor(Math.random() * 100),
//     wtp: Math.floor(Math.random() * 100),
//     age: Math.floor(Math.random() * 30),
//     simulation: Math.floor(Math.random() * 100),
//     qualifiedCandidate: Math.floor(Math.random() * 100),
//     trainingRecordsCertificates: user.id,
//   }
// }
