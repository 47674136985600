import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import humps from 'humps'
import { getToken } from '../../services/auth/auth-action'
import { customRequestData, deepLoop } from './tools'

const createClient = () => {
  const ax = axios.create()
  ax.interceptors.request.use((request: any) => {
    request.url = `${(window as any)._env_.apiHost}/${request.url}`
    request.headers.common['Authorization'] = `Bearer ${getToken()}`
    if (request.params) {
      request.params = deepLoop(request.params, modifyRequestData)
    }
    if (request.data) {
      request.data = deepLoop(request.data, modifyRequestData)
      // request.data = humps.decamelizeKeys(request.data)
      customRequestData(request)
    }
    return request
  })
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      if (response.headers['content-type'].includes('application/json')) {
        response.data = humps.camelizeKeys(response.data)
        // deepLoop(response.data, data => {
        //   return data
        // })
      }
      return response
    },
    (error: any) => Promise.reject(error),
  )
  return ax
}

const modifyRequestData = (data: any) => {
  if (dayjs.isDayjs(data)) {
    return data.format()
  }
  return data
}

export const blcpIdpClient = createClient()

export const blcpIdpApiWrapper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method
    return Promise.resolve(res)
  } catch (e) {
    const { response, message } = e as unknown as any
    let { data } = response || {}
    if (data instanceof Blob) {
      const responseFromBlob = await data.text()
      data = JSON.parse(responseFromBlob || '')
    }
    const { message: errorMessage, errorCode, meta = {} } = data || {}
    return Promise.reject({ message: errorCode || errorMessage || message || e, meta: meta })
  }
}
