import { api } from '../../utils/api'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { NotificationCount, News } from './notification-type'
import { IDP_PLANNINGS } from '../idp-planning/idp-planning-query'

export const NOTIFICATION_URL = `notifications`
export const NOTIFICATIONCOUNT_URL = `notifications/count`

export const useGetNotificationUser = (userId: string) => {
  return useInfiniteQuery(
    [NOTIFICATION_URL],
    async ({ pageParam = 1 }) => {
      const data = await api.blcpIdp.get<News>(NOTIFICATION_URL, {
        userId: userId,
        page: pageParam,
        limit: 5,
      })

      return data.data
    },
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.bottom.meta
        return currentPage < totalPages ? currentPage + 1 : null
      },
      // refetchInterval: 5 * 1000,
      onError: () => {}, //disable error
    },
  )
}

export const useGetCountNotificationUser = (userId: string) => {
  return useQuery(
    [NOTIFICATIONCOUNT_URL],
    async () => {
      const data = await api.blcpIdp.get<NotificationCount>(NOTIFICATIONCOUNT_URL, {
        userId: userId,
      })
      return data.data.count
    },
    {
      // refetchInterval: 5 * 1000,
      onError: () => {}, //disable error
    },
  )
}

export const useReadNotification = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (notificationId: string) => {
      const data = await api.blcpIdp.patch<NotificationCount>(
        `${NOTIFICATION_URL}/${notificationId}/read`,
        {
          notificationId: notificationId,
        },
      )
      return data.data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([IDP_PLANNINGS])
        queryClient.invalidateQueries([NOTIFICATION_URL])
        queryClient.invalidateQueries([NOTIFICATIONCOUNT_URL])
      },
    },
  )
}
