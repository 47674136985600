import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import { CSSProperties, ReactNode, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BLACK_000000, WHITE_FFFFFF } from '../constant/colors'
import { AppCtx } from '../constant/contexts'
import Button from './common/Button'
import Text from './common/Text'
import CloseIcon from '@material-ui/icons/Close'

export const useAppModalStyles = makeStyles({
  appModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  layout: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: WHITE_FFFFFF,
    padding: '2.188rem',
    borderRadius: '1.25rem',
    '&:focus': {
      outline: 'unset',
    },
  },
  header: {
    marginBottom: '0.625rem',
    textAlign: 'left',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  body: {
    marginBottom: '1.625rem',
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    '& > *:first-of-type(1)': {
      marginRight: '0.625rem',
    },
  },
})

export type AppModalProps = {
  visible: boolean
  title?: string
  message?: string
  children?: ReactNode
  onOk?: () => void
  onOkText?: string
  onCancel?: () => void
  onCancelText?: string
  closeOnClickOutside?: boolean
  style?: CSSProperties
  closeIcon?: boolean
  className?: string
}
export const AppModal = () => {
  const [state, setState, { initialValue }] = useContext(AppCtx)
  const {
    visible,
    title,
    message,
    children,
    onOk,
    onOkText,
    onCancel,
    onCancelText,
    closeOnClickOutside = true,
    closeIcon = false,
    style,
    className,
  } = state.appModal

  const classes = useAppModalStyles()
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    setState({
      appModal: initialValue.appModal,
    })
  }, [initialValue.appModal, setState])

  const onOkClick = useCallback(() => {
    onClose()
    onOk?.()
  }, [onOk, onClose])

  const onCancelClick = useCallback(() => {
    onClose()
    onCancel?.()
  }, [onCancel, onClose])

  return (
    <Modal
      open={visible}
      onClose={() => {
        closeOnClickOutside && onCancelClick()
      }}
      className={`${classes.appModal} ${className}`}
      aria-labelledby="app-modal-title"
      aria-describedby="app-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
    >
      <div
        className={`content ${classes.layout}`}
        style={{
          ...style,
        }}
      >
        {closeIcon && (
          <div className={classes.closeIcon}>
            <CloseIcon
              onClick={onCancelClick}
              fontSize={'small'}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>
        )}
        {!children && (
          <>
            {title && (
              <div className={classes.header}>
                <Text size={25} weight={500}>
                  {t(title)}
                </Text>
              </div>
            )}
            {message && (
              <div className={classes.body}>
                <Text size={18}>{t(message)}</Text>
              </div>
            )}
            {
              <div className={classes.footer}>
                {onCancel && (
                  <Button
                    buttonStyle="border"
                    fontSize={13}
                    width={112}
                    height={31}
                    textColor={BLACK_000000}
                    onClick={onCancelClick}
                  >
                    {t(onCancelText || 'No')}
                  </Button>
                )}
                {onOk && (
                  <Button
                    buttonStyle="fill"
                    fontSize={13}
                    width={112}
                    height={31}
                    onClick={onOkClick}
                    style={{
                      marginLeft: '0.625rem',
                    }}
                  >
                    {t(onOkText || 'Yes')}
                  </Button>
                )}
              </div>
            }
          </>
        )}
        {children && children}
      </div>
    </Modal>
  )
}
