import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router'
import App from './App'
import {
  BLACK_000000,
  BLUE_004D99,
  GRAY_171717,
  GRAY_707070,
  GREEN_0BAC0B,
  GREEN_AFBD00,
  RED_FF0000,
  RED_FFD0D0,
  WHITE_FFFFFF,
} from './constant/colors'
import i18n from './constant/i18n'
import reportWebVitals from './reportWebVitals'
import './index.css'
import './initialize'
import './utils/tracking'

const history = createBrowserHistory()

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: BLUE_004D99,
      light: BLUE_004D99,
      dark: BLUE_004D99,
      contrastText: WHITE_FFFFFF,
    },
    secondary: {
      main: GREEN_AFBD00,
      light: GREEN_AFBD00,
      dark: GREEN_AFBD00,
      contrastText: WHITE_FFFFFF,
    },
    error: {
      main: RED_FF0000,
      light: RED_FF0000,
      dark: RED_FF0000,
      contrastText: WHITE_FFFFFF,
    },
    warning: {
      main: RED_FFD0D0,
      light: RED_FFD0D0,
      dark: RED_FFD0D0,
      contrastText: BLACK_000000,
    },
    info: {
      main: GRAY_707070,
      light: GRAY_707070,
      dark: GRAY_707070,
      contrastText: WHITE_FFFFFF,
    },
    success: {
      main: GREEN_0BAC0B,
      light: GREEN_0BAC0B,
      dark: GREEN_0BAC0B,
      contrastText: WHITE_FFFFFF,
    },
  },
  typography: {
    fontFamily: `"Kanit"`,
    fontSize: 17,
  },
  overrides: {
    MuiTouchRipple: {
      root: {
        color: GRAY_171717,
      },
      child: {
        color: GRAY_171717,
      },
    },
  },
})

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <App id="app-root" />
      </MuiThemeProvider>
    </Router>
  </I18nextProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
