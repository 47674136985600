import {
  Button as MuiButton,
  makeStyles,
  Theme,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core'
import { PropsWithChildren } from 'react'
import { WHITE_FFFFFF, BLUE_004D99, BLACK_00000D, GRAY_C7C7C7 } from '../../constant/colors'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

type StyleAttr = {
  width?: number
  height?: number
  fontSize?: number
  textColor?: string
  disabled?: boolean
}

const useStyle = makeStyles<Theme, StyleAttr>({
  root: {
    fontFamily: "'Kanit', sans-serif",
    fontWeight: 400,
    fontSize: ({ fontSize = 14 }) => `${fontSize / 16}rem`,
    borderRadius: '7px',
    textTransform: 'none',
    padding: '6px 15px',
    height: ({ height = 45 }) => `${height / 16}rem`,
    width: ({ width }) => (width ? `${width / 16}rem` : undefined),
  },
  fill: {
    backgroundColor: ({ disabled }) => (disabled ? GRAY_C7C7C7 : BLUE_004D99),
    color: ({ textColor = WHITE_FFFFFF }) => `${textColor} !important`,
    '&:hover': {
      backgroundColor: `${BLUE_004D99}cc`,
    },
  },
  border: {
    backgroundColor: 'transparent',
    color: ({ textColor = BLACK_00000D }) => textColor,
    border: `1px solid ${GRAY_C7C7C7}`,
  },
  noBorder: {
    paddingRight: '0px',
    paddingLeft: '0px',
    color: ({ textColor = BLUE_004D99 }) => textColor,
  },
})

type ButtonProps = {
  buttonStyle?: 'fill' | 'border' | 'no-border'
  width?: number
  height?: number
  loading?: boolean
  fontSize?: number
  textColor?: string
} & MuiButtonProps

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    className = '',
    buttonStyle = 'fill',
    width,
    height,
    loading,
    fontSize = 14,
    textColor,
    ...restProps
  } = props
  const classes = useStyle({ width, height, fontSize, textColor, disabled: props.disabled })
  return (
    <MuiButton
      className={`${classes.root} ${classes[buttonStyle]} ${className}`}
      startIcon={
        loading ? (
          <MuiCircularProgress size={`${fontSize / 16}rem`} style={{ color: WHITE_FFFFFF }} />
        ) : null
      }
      {...restProps}
    >
      {children}
    </MuiButton>
  )
}

export default Button
