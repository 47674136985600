import React, { PropsWithChildren, useContext } from 'react'
import { Box, IconButton, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { CssBaseline } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { ReactComponent as Logo } from '../../assets/logo/logo.svg'
import { ReactComponent as SubLogo } from '../../assets/logo/sub-logo.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icon/logout.svg'
import { BLUE_004D99 } from '../../constant/colors'
import Topbar from './Topbar'
import MenuList from './MenuList'
import { AppCtx } from '../../constant/contexts'
import Text from '../common/Text'
import { useCurrUser, useLogout } from '../../services/auth/auth-query'
import * as Colors from '../../constant/colors'
import { breakpoints } from '../../utils/responsive-helper'
import SwitchLanguageButton from './SwitchLanguageButton'
import { useTranslation } from 'react-i18next'

const toolbarHeight = 5 //rem
const drawerWidth = 17.5 //rem

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuSelected: {
    width: '0.3rem',
    height: '2rem',
    background: `${BLUE_004D99} 0% 0% no-repeat padding-box`,
    borderRadius: '0 6rem 6rem 0',
    position: 'absolute',
    left: 0,
  },
  menuItemList: {
    paddingLeft: 0,
    margin: '1.3rem 0',
  },
  drawer: {
    [breakpoints.up('ml')]: {
      width: `${drawerWidth}rem`,
      flexShrink: 0,
    },
    '& .MuiDrawer-paper': {
      width: `${drawerWidth}rem`,
      boxShadow: '0.13rem 0 0.5rem #0000000D',
      border: 'unset',
    },
  },
  drawerPaper: {
    width: `${drawerWidth}rem`,
  },
  iconButton: {
    width: '5rem',
    height: '5rem',
    '& svg': {
      fill: Colors.BLACK_171717,
    },
  },
  logoLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${toolbarHeight}rem`,
    marginBottom: '2.125rem',
    paddingRight: '2rem',
    paddingTop: '1.3rem',
  },
  logoLayoutForMobile: {
    margin: '0.5rem 2rem 1.875rem',
  },
  content: {
    width: `calc(100% - ${drawerWidth}rem)`,
    marginTop: `${toolbarHeight}rem`,
    flexGrow: 1,
    [breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  logoutButton: {
    marginTop: '0.5rem',
    marginLeft: '2rem',
    marginBottom: '3rem',
  },
}))

type LayoutProps = {}

const Layout = (props: PropsWithChildren<LayoutProps>) => {
  const { t } = useTranslation()
  const { children } = props
  const [appState] = useContext(AppCtx)
  const classes = useStyle()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const { data: user } = useCurrUser()
  const mobile = useMediaQuery(breakpoints.down('ml'))
  const desktop = useMediaQuery(breakpoints.up('ml'))
  const { mutate: logout } = useLogout()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Topbar
        onHambergerClick={handleDrawerToggle}
        drawerWidth={`${drawerWidth}rem`}
        toolbarHeight={`${toolbarHeight}rem`}
      />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {mobile && (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            style={{ position: 'fixed', zIndex: 1100 }}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={classes.iconButton}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Box height="78" className={classes.logoLayoutForMobile}>
              <Logo />
            </Box>
            <Box display="flex" flexDirection="column" justifyItems="center" padding="0 32px 24px">
              <Text size={17} weight={500} color={Colors.BLACK_171717}>
                {`${user?.firstname} ${user?.lastname}`}
              </Text>
              <Text size={15} weight={200} color={Colors.BLACK_171717}>
                {user?.position?.name || '-'}
              </Text>
            </Box>
            <Box
              overflow="auto"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <MenuList onMenuClick={handleDrawerToggle} />
              <Box display="flex" justifyItems="flex-start" className={classes.logoutButton}>
                <SwitchLanguageButton />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.logoutButton}
                onClick={() => {
                  logout()
                }}
              >
                <LogoutIcon style={{ marginRight: '0.9rem' }} width="1.563rem" height="1.563rem" />
                <Text size={16} weight={300} color={Colors.GRAY_505E6C}>
                  {t('drawer.logout')}
                </Text>
              </Box>
            </Box>
          </Drawer>
        )}
        {desktop && (
          <Drawer variant="permanent">
            <div className={classes.logoLayout}>
              <Logo width="9.688rem" height="1.813rem" />
              <SubLogo width="4.875rem" height="1.438rem" />
            </div>
            <MenuList />
          </Drawer>
        )}
      </nav>
      <main
        className={classes.content}
        style={{
          marginTop: appState.topbarTab.visibled ? `${toolbarHeight + 3.75}rem` : undefined,
        }}
      >
        {children}
      </main>
    </div>
  )
}

export default Layout
