import { AxiosRequestConfig } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { useSnackbar } from '../../utils/custom-hook'
import { downloadFile } from '../../utils/helper'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { USERS } from '../auth/auth-query'
import { CERTIFICATES } from '../user/user-query'
import {
  DeleteCertificateUserParams,
  DeleteCertificateUserResponse,
  DownloadCertificateUserParams,
  DownloadCertificateUsersParams,
  GetCertificateUsersParams,
  GetCertificateUsersResponse,
  ImportCertificateUsersParams,
  ImportCertificateUsersResponse,
} from './certificate-user-types'

export const CERTIFICATE_USER = `certificate-users`
export const DOWNLOAD_CERTIFICATE_USER = `${CERTIFICATE_USER}/exports`

export const useGetCertificateUsers = (params: GetCertificateUsersParams) => {
  const { templateId, userId } = params
  return useQuery(
    [CERTIFICATE_USER, params],
    async () => {
      const { data } = await api.blcpIdp.get<GetCertificateUsersResponse>(CERTIFICATE_USER, params)
      return data.certificateUsers
    },
    {
      enabled: !!templateId && !!userId,
    },
  )
}

export const useImportCertificateUsers = (userId: string, option?: AxiosRequestConfig) => {
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  return useMutation<ImportCertificateUsersResponse, string, ImportCertificateUsersParams>(
    async (params) => {
      trackingEvent(Category.Admin, 'Import Certificate')
      const { data } = await api.blcpIdp.post<ImportCertificateUsersResponse>(
        CERTIFICATE_USER,
        {
          ...params,
          userId,
        },
        {
          contentType: ContentType.JSON,
          ...option,
        },
      )
      return data
    },
    {
      onSuccess: () => {
        // validate get certificates in user service
        queryClient.invalidateQueries([CERTIFICATE_USER])
        queryClient.invalidateQueries([USERS, userId, CERTIFICATES])
      },
    },
  )
}

export const useDownloadCertificateUsers = (option?: AxiosRequestConfig) => {
  return useMutation(async (params: DownloadCertificateUsersParams) => {
    const res = await api.blcpIdp.getFile(DOWNLOAD_CERTIFICATE_USER, params, option)
    downloadFile(res)
  })
}

export const useDownloadCertificateUser = (option?: AxiosRequestConfig) => {
  const trackingEvent = useTrackingEvent()
  return useMutation(async (params: DownloadCertificateUserParams) => {
    const { certificateUserId } = params
    trackingEvent(Category.MyCapability, 'Download Certificate')
    const res = await api.blcpIdp.getFile(
      `${CERTIFICATE_USER}/${certificateUserId}/export`,
      params,
      option,
    )
    downloadFile(res)
  })
}

export const useDeleteCertificateUser = (userId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    (params: DeleteCertificateUserParams) => {
      trackingEvent(Category.MyCapability, 'Delete Certificate')
      const { certificateUserId } = params
      return api.blcpIdp.delete<DeleteCertificateUserResponse>(
        `${CERTIFICATE_USER}/${certificateUserId}/delete`,
        params,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS, userId, CERTIFICATES])
        queryClient.invalidateQueries([CERTIFICATE_USER])
        snackbar({ type: 'success', message: t('status.success') })
      },
    },
  )
}
