import {
  AppBar,
  Badge,
  Box,
  IconButton,
  makeStyles,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SwitchLanguageButton from './SwitchLanguageButton'
import Text from '../common/Text'
import * as Colors from '../../constant/colors'
import { ReactComponent as LogoutIcon } from '../../assets/icon/logout.svg'
import { ReactComponent as BellIcon } from '../../assets/icon/bell.svg'
import { useCurrUser, useLogout } from '../../services/auth/auth-query'
import { breakpoints } from '../../utils/responsive-helper'
import React, { useCallback, useRef } from 'react'
import NotificationPopover from '../../pages/Notification/NotificationPopover'
import { useGetCountNotificationUser } from '../../services/notification/notification-query'
import { useRouter } from '../../utils/helper'
import * as paths from '../../constant/path'
import { useModal } from '../../utils/custom-hook'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    boxShadow: '0.13rem 0 0.5rem #0000000D',
    [breakpoints.up('ml')]: {
      width: (props: any) => `calc(100% - ${props.drawerWidth})`,
      marginLeft: (props: any) => props.drawerWidth,
    },
  },
  toolbar: {
    height: (props: any) => props.toolbarHeight,
    backgroundColor: 'white',
    boxShadow: '0.13rem 0 0.5rem #0000000D',
    padding: '0 4.375rem',
    [breakpoints.down('ml')]: {
      padding: theme.spacing(3),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '& svg': {
      fill: Colors.BLACK_171717,
    },
    [breakpoints.up('ml')]: {
      display: 'none',
    },
  },
  logoutButton: {
    cursor: 'pointer',
    '&:hover': {
      '& path, > div': {
        color: Colors.BLUE_004D99,
        stroke: Colors.BLUE_004D99,
        fill: Colors.BLUE_004D99,
      },
    },
  },
  notificationButton: {
    cursor: 'pointer',
    '&:hover': {
      '& path': {
        stroke: Colors.BLUE_004D99,
        fill: Colors.BLUE_004D99,
      },
    },
  },
  swithcLanguageButton: {
    marginRight: '1.6rem',
  },
}))

type TopbarProps = {
  onHambergerClick: () => void
  drawerWidth: string
  toolbarHeight: string
}

const Topbar = (props: TopbarProps) => {
  const { t } = useTranslation()
  const { onHambergerClick, drawerWidth, toolbarHeight } = props
  const classes = useStyle({ drawerWidth, toolbarHeight })
  const { mutate: logout } = useLogout()
  const { data: user } = useCurrUser()
  const { id = '' } = user || {}
  const desktop = useMediaQuery(breakpoints.up('ml'))
  const isMobile = useMediaQuery(breakpoints.down('md'))
  const { confirm } = useModal()
  const router = useRouter()
  const { push } = router

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const { data: countNoti = 0 } = useGetCountNotificationUser(id) || {}

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)

      isMobile && push(paths.notification())
    },
    [isMobile, push],
  )
  const notiIconRef = useRef<any>()

  return (
    <AppBar position="fixed" className={classes.appBar} id="appbar">
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onHambergerClick}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flexDirection="column" justifyContent="center">
            {desktop && (
              <>
                <Text size={18} weight={400} color={Colors.BLACK_000000}>
                  {`${user?.firstname} ${user?.lastname}`}
                </Text>
                <Text size={14} weight={200} color={Colors.GRAY_505E6C}>
                  {user?.displayPosition || '-'}
                </Text>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {desktop && (
              <Box
                display="flex"
                justifyItems="flex-start"
                className={classes.swithcLanguageButton}
              >
                <SwitchLanguageButton />
              </Box>
            )}

            <IconButton onClick={handleClick} ref={notiIconRef}>
              <Badge badgeContent={countNoti} color="error" className={classes.logoutButton}>
                <BellIcon width="1.5rem" height="1.563rem" />
              </Badge>
            </IconButton>

            {desktop && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.logoutButton}
                onClick={() => {
                  confirm({
                    title: t('modal.areYouSure'),
                    message: t('modal.signoutMsg'),
                    onOk: () => {
                      logout()
                    },
                  })
                }}
              >
                <LogoutIcon
                  style={{ marginRight: '0.6rem', marginLeft: '2.188rem' }}
                  width="1.563rem"
                  height="1.563rem"
                />
                <Text size={17} weight={300} color={Colors.GRAY_505E6C}>
                  {t('drawer.logout')}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Toolbar>

      <div id="topbarPortal" />
      {!isMobile && <NotificationPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
    </AppBar>
  )
}

export default Topbar
