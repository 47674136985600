import { debounce, DebounceSettings } from 'lodash'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppModalProps } from '../components/AppModal'
import { AppSnackbarProps } from '../components/AppSnackbar'
import { AppCtx } from '../constant/contexts'
import { useQueryParams } from './helper'

type UseModalProps = Partial<Omit<AppModalProps, 'visible'>>
type UseModalOptionProps = Partial<
  Pick<UseModalProps, 'style' | 'closeIcon' | 'closeOnClickOutside'>
>
type UseModalConfirmProps = Required<Pick<UseModalProps, 'title' | 'onOk'>> &
  Partial<Pick<UseModalProps, 'message' | 'onOkText' | 'onCancel' | 'onCancelText'>> &
  UseModalOptionProps
type UseModalErrorProps = Required<Pick<UseModalProps, 'title'>> &
  Partial<Pick<UseModalProps, 'message' | 'onOkText' | 'onOk'>> &
  UseModalOptionProps
export const useModal = () => {
  const { t } = useTranslation()
  const [state, setState, { initialValue }] = useContext(AppCtx)
  const openModal = useCallback(
    (props: UseModalProps) => {
      setState({
        appModal: {
          visible: true,
          ...props,
        },
      })
    },
    [setState],
  )

  const confirm = useCallback(
    (props: UseModalConfirmProps) => {
      const {
        title,
        message,
        onOk,
        onOkText = t('button.yes'),
        onCancel,
        onCancelText = t('button.no'),
        ...restProps
      } = props
      openModal({
        title,
        message,
        onOk,
        onOkText,
        onCancel: () => {
          onCancel?.()
        },
        onCancelText,
        ...restProps,
      })
    },
    [openModal, t],
  )

  const error = useCallback(
    (props: UseModalErrorProps) => {
      const { title, message, onOk, onOkText, ...restProps } = props
      openModal({
        title,
        message,
        onOk: () => {
          onOk?.()
        },
        onOkText,
        onCancel: undefined,
        ...restProps,
      })
    },
    [openModal],
  )

  const open = useCallback(
    (
      props: Pick<
        UseModalProps,
        'children' | 'closeIcon' | 'closeOnClickOutside' | 'style' | 'className'
      >,
    ) => {
      const { children, ...restProps } = props
      openModal({
        children,
        ...restProps,
      })
    },
    [openModal],
  )

  const closeModal = useCallback(() => {
    setState({
      appModal: initialValue.appModal,
    })
  }, [initialValue.appModal, setState])

  return { confirm, error, open, closeModal, visible: state.appModal.visible }
}

type UseSnackbarProps = Partial<Omit<AppSnackbarProps, 'visible'>>
export const useSnackbar = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, setState] = useContext(AppCtx)
  const openSnackbar = useCallback(
    (props: UseSnackbarProps) => {
      setState({
        appSnackbar: {
          visible: true,
          ...props,
        },
      })
    },
    [setState],
  )

  const snackbar = useCallback(
    (props: Pick<UseSnackbarProps, 'message' | 'type' | 'description'>) => {
      const { message, type = 'info', description = '' } = props
      openSnackbar({
        message,
        type,
        description,
      })
    },
    [openSnackbar],
  )

  return { snackbar }
}

/**
 * default params
 * page = 1, pageSize = 5
 * */
type UsePageRunngerParam = {
  initialPage?: number
  initialPageSize?: number
  alias?: {
    page?: string
    perPage?: string
  }
}
export const usePageRunner = (params?: UsePageRunngerParam) => {
  const { initialPage = 1, initialPageSize = 5, alias } = params || {}
  const { page: pageParamName = 'page', perPage: perPageParamName = 'perPage' } = alias || {}
  const { query, setParam } = useQueryParams<any>()
  const { [pageParamName]: page = initialPage, [perPageParamName]: perPage = initialPageSize } =
    query
  const [mounted, setMounted] = useState(false)

  const setPage = useCallback(
    (page) => {
      setParam({
        [perPageParamName]: perPage,
        [pageParamName]: page,
      })
    },
    [pageParamName, perPage, perPageParamName, setParam],
  )
  const setPerPage = useCallback(
    (perPage) => {
      setParam({
        [perPageParamName]: perPage,
        [pageParamName]: 1,
      })
    },
    [pageParamName, perPageParamName, setParam],
  )

  const setNewPage = useCallback(
    (newPage: number) => {
      setPage(newPage)
    },
    [setPage],
  )
  const changePageSize = useCallback(
    (newPageSize: number) => {
      setPerPage(newPageSize)
    },
    [setPerPage],
  )

  // useEffect(() => {
  //   if (!perPage) {
  //     setPage(initialPage)
  //     setPerPage(initialPageSize)
  //     setMounted(true)
  //   }
  // }, [initialPage, initialPageSize, mounted, perPage, setPage, setPerPage])

  return {
    page: Number(page),
    setNewPage,
    pageSize: Number(perPage),
    changePageSize,
  }
}

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings,
) => {
  return useMemo(() => {
    return debounce(func, wait, options)
  }, [func, options, wait])
}

export const useVisible = () => {
  const [visible, setVisible] = useState(false)

  const open = useCallback(() => {
    setVisible(true)
  }, [])

  const close = useCallback(() => {
    setVisible(false)
  }, [])

  return { visible, open, close }
}
