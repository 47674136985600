import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import th from './th.json'
import en from './en.json'
import Backend from 'i18next-http-backend'
import { initLangKey } from '../../components/layout/SwitchLanguageButton'

const resources = {
  th: {
    translation: th,
  },
  en: {
    translation: en,
  },
}

const initLang = localStorage.getItem(initLangKey) || 'th'

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: initLang,
    // fallbackLng: 'th',
    ns: ['backend'],
    react: {
      useSuspense: false,
      wait: true,
    },
    backend: {
      loadPath: (window as any)._env_.apiHost.replace('/api/v1', '/locales/error-{{lng}}.json'),
    },
    resources,
    // debug: true,
  })
i18next.reloadResources()

export default i18next
