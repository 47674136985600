import qs from 'querystring'

type ParamType = { [p: string]: any } | undefined
type Option<T extends ParamType, V extends ParamType> = {
  routeParam?: T
  queryParam?: V
}

const generate =
  <RouteParams extends ParamType = undefined, QueryParams extends ParamType = undefined>(
    url: string,
  ) =>
  (option?: Option<RouteParams, QueryParams>) => {
    const { routeParam, queryParam } = option || {}
    let newQueryParam = ''
    if (queryParam) {
      newQueryParam = `?${qs.stringify(queryParam)}`
    }

    let newUrl = url
    if (routeParam) {
      const urls = url.split('/')
      newUrl = urls
        .map((u) => {
          if (/:.+/.test(u)) {
            return routeParam[u.slice(1)]
          }
          return u
        })
        .join('/')
    }

    return `${newUrl}${newQueryParam}`
  }

export const notFound = generate('/404')
export const home = generate('/home')
export const myAsprireation = generate('/my-aspiration')
export const idp = generate('/idp')
export const idpMe = generate(`${idp()}/me`)
export const idpTeam = generate(`${idp()}/team`)
export const idpTeamDetail = generate<{ userId: string }>(`${idp()}/team/:userId`)
export const idpReview = generate('/review')
export const idpReviewMe = generate(`${idpReview()}/me`)
export const idpReviewTeam = generate(`${idpReview()}/team`)
export const idpReviewTeamDetail = generate<{ userId: string }>(`${idpReview()}/team/:userId`)
export const myCapability = generate('/my-capability')
export const myCapabilityOverall = generate(`${myCapability()}/overall-result`)
export const myCapabilityDevelopmentRecord = generate(`${myCapability()}/development-record`)
export const myCapabilityCertificate = generate(`${myCapability()}/certificate`)
export const admin = generate('/admin')
export const adminHomeSetting = generate(`${admin()}/home-setting`)
export const adminManageDatabase = generate(`${admin()}/manage-database`)
export const adminManageIdpPlanning = generate(`${admin()}/manage-idp-planning`)
export const adminManageIdpPlanningUserIdp = generate<{ userId: string }>(
  `${adminManageIdpPlanning()}/:userId`,
)
export const adminManageDevelopmentRecord = generate(`${admin()}/manage-development-record`)
export const adminManageIdpReview = generate(`${admin()}/manage-idp-review`)
export const adminManageReport = generate(`${admin()}/manage-report`)
export const adminManageQualifiedCandidate = generate<{}, Record<string, any>>(
  `${admin()}/manage-qualified-candidate`,
)

export const adminManageAdmin = generate(`${admin()}/manage-admin`)

export const wiki = generate('/wiki')
export const login = generate('/login')
export const notification = generate('/notification')

export const competencyDashboard = generate('/competency-dashboard')
export const competencyDashboardOverall = generate(`${competencyDashboard()}/overall`)
export const competencyDashboardIndividualProfile = generate(
  `${competencyDashboard()}/individual-profile`,
)
export const competencyDashboardEmployeeCapability = generate<{ userId: string }>(
  `${competencyDashboard()}/employee-capability/:userId`,
)
