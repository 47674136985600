import { useCallback } from 'react'
import ReactGA from 'react-ga'
import { useCurrUser } from '../services/auth/auth-query'

const TRACKING_ID = (window as any)._env_.gaTrackingId
ReactGA.initialize(TRACKING_ID)

type UserInfo = {
  firstname: string
  lastname: string
}

export enum Category {
  Auth = 'Authentication',
  Home = 'Home',
  MyAspiration = 'My Aspiration',
  MyIdp = 'My IDP Planning',
  MyTeam = 'My Team',
  MyIdpReview = 'My IDP Review',
  MyTeamReview = 'My Team Review',
  MyCapability = 'MyCapability',
  Admin = 'Admin',
  Other = 'Other',
}

const page = (pathname: string) => {
  ReactGA.set({ page: pathname })
  ReactGA.pageview(pathname)
}

const event = (category: Category, action: string, userInfo?: UserInfo) => {
  ReactGA.event({
    category,
    action,
    label: `User: ${userInfo?.firstname} ${userInfo?.lastname}`,
  })
}

export const useTrackingEvent = () => {
  const { data: user } = useCurrUser()
  return useCallback(
    (category: Category, action: string) => {
      event(
        category,
        action,
        user ? { firstname: user?.firstname, lastname: user?.lastname } : undefined,
      )
    },
    [user],
  )
}

const tracking = {
  page,
  event,
}

export default tracking
