import { AxiosRequestConfig } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { useSnackbar } from '../../utils/custom-hook'
import { downloadFile } from '../../utils/helper'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { USERS } from '../auth/auth-query'
import { TRAINING_RECORD_USERS } from '../training-record-user/training-record-user-query'
import { CERTIFICATES } from '../user/user-query'
import {
  DownloadTrainingRecordParams,
  GetTrainingRecordResponse,
  ImportTrainingRecordParams,
  ImportTrainingRecordResponse,
} from './training-record-types'

export const TRAINING_RECORDS = `training-records`

export const useGetTrainingRecord = (templateId: string) => {
  return useQuery(
    [TRAINING_RECORDS, { templateId }],
    async () => {
      const { data } = await api.blcpIdp.get<GetTrainingRecordResponse>(TRAINING_RECORDS, {
        templateId,
      })
      return data.trainingRecords
    },
    {
      onSuccess: () => {},
      enabled: !!templateId,
    },
  )
}

export const useImportTrainingRecord = (axiosOption?: AxiosRequestConfig) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()

  return useMutation<ImportTrainingRecordResponse, string, ImportTrainingRecordParams>(
    async (params) => {
      trackingEvent(Category.Admin, 'Import tranning record')
      const { attachmentId, templateId } = params
      const { data } = await api.blcpIdp.post<ImportTrainingRecordResponse>(
        TRAINING_RECORDS,
        { attachmentId, templateId },
        { contentType: ContentType.JSON, ...axiosOption },
      )
      return data
    },
    {
      onSuccess: () => {
        // validate get certificates in user service
        queryClient.invalidateQueries({
          predicate: (query) => {
            const { queryKey } = query
            if (queryKey[0] === USERS && queryKey[2] === CERTIFICATES) {
              return true
            } else {
              return false
            }
          },
        })
        queryClient.invalidateQueries([TRAINING_RECORDS])
        queryClient.invalidateQueries([TRAINING_RECORD_USERS])
        snackbar({
          type: 'success',
          message: t('status.success'),
          description: 'Import Tranining Record Success',
        })
      },
    },
  )
}

export const useDownloadTrainingRecord = (option?: AxiosRequestConfig) => {
  const trackingEvent = useTrackingEvent()
  return useMutation(async (params: DownloadTrainingRecordParams) => {
    trackingEvent(Category.Admin, 'Download training record')
    const { trainingRecordId } = params
    const res = await api.blcpIdp.getFile(
      `${TRAINING_RECORDS}/${trainingRecordId}/export`,
      {},
      option,
    )
    downloadFile(res)
  })
}
