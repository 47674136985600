import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import Text from '../../components/common/Text'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import ScheduleIcon from '@material-ui/icons/Schedule'
import dayjs from 'dayjs'
import {
  useGetCountNotificationUser,
  useGetNotificationUser,
  useReadNotification,
} from '../../services/notification/notification-query'
import { useCurrUser } from '../../services/auth/auth-query'
import { WHITE_FFFFFF, GRAY_BEBEBE } from '../../constant/colors'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { formatDateByLang } from '../../utils/helper'

interface NotificationProps {
  anchorEl: any
  setAnchorEl: any
}
const useStyles = makeStyles({
  positionTextHead: {
    paddingLeft: '1.875rem',
    paddingBottom: '0.625rem',
    paddingTop: '0.625rem',
    paddingRight: '0.625rem',
  },
  buttonSize: {
    width: 'max-content',
    height: '3.125rem',
  },
  positionDetail: {
    paddingLeft: '1.875rem',
    paddingBottom: '0.625rem',
    paddingTop: '0.625rem',
    paddingRight: '1.875rem',
    background: GRAY_BEBEBE,
  },
  popoversize: {
    width: '18.75rem',
    maxHeight: '37.5rem',
    overflow: 'auto',
  },
  positionButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paddingHead: {
    padding: '1.875rem',
  },
  dateTime: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0.313rem',
  },
  textDecoration: {
    textDecoration: 'none',
  },
  paddingLeft: {
    paddingLeft: '0.625rem',
  },
  sizeIcon: {
    fontSize: '0.625rem',
  },
})

export const NotificationPopover = (props: NotificationProps) => {
  const { t, i18n } = useTranslation()
  const { anchorEl, setAnchorEl } = props
  const { data } = useCurrUser()
  const classes = useStyles()
  const { id = '' } = data || {}

  const { data: noti, fetchNextPage, hasNextPage } = useGetNotificationUser(id)
  const { data: countNoti = 0 } = useGetCountNotificationUser(id)

  const [alignment] = React.useState<string | null>('left')

  const [, setLanguage] = useState('en')
  const { mutate: readNoti } = useReadNotification()

  useMemo(() => {
    if (alignment === 'left') {
      setLanguage('en')
    } else if (alignment === 'right') {
      setLanguage('th')
    }
  }, [alignment])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])
  const readNotification = useCallback(
    (notificationId: string) => {
      readNoti(notificationId)
      handleClose()
    },

    [handleClose, readNoti],
  )

  let count = 0

  const open = Boolean(anchorEl)
  const ids = open ? 'simple-popover' : undefined
  return (
    <>
      <div>
        <div style={{ width: '22.5rem' }}>
          <Popover
            id={ids}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: '22.5rem',
                maxHeight: '50rem',
              },
            }}
          >
            <div>
              <div className={classes.paddingHead}>
                <Text>{t('notification.title')}</Text>
                <Text weight={300}>{`${t('notification.youHave')} ${countNoti} ${t(
                  'notification.unreadMsg',
                )}`}</Text>
              </div>
              <Divider />
              {countNoti > 0 && (
                <>
                  <div className={classes.positionTextHead}>
                    <Text weight={300}>{t('notification.new')}</Text>
                  </div>
                </>
              )}
              <div>
                {noti?.pages.map((noti1, index) =>
                  noti1?.top.map(({ notification, isRead }) => {
                    if (isRead === false && count < 2) {
                      const {
                        title = '{}',
                        description = '{}',
                        createdAt,
                        meta = '{}',
                        id,
                      } = notification || {}
                      var titleLang = JSON.parse(title)
                      var descripionLang = JSON.parse(description)
                      var metaLink = JSON.parse(meta)
                      count += 1
                      var titleShow = ''
                      var descriptionShow = ''
                      if (i18n.language === 'en') {
                        titleShow = titleLang.en
                        descriptionShow = descripionLang.en
                      } else if (i18n.language === 'th') {
                        titleShow = titleLang.th
                        descriptionShow = descripionLang.th
                      }
                      return (
                        <div key={id}>
                          <Link
                            to={`/${metaLink.name}`}
                            className={classes.textDecoration}
                            onClick={() => readNotification(id!)}
                          >
                            <div className={classes.positionDetail}>
                              <Text size={17}> {titleShow} </Text>
                              <Text weight={300} size={15}>
                                {descriptionShow}
                              </Text>
                              <div className={classes.dateTime}>
                                <ScheduleIcon className={classes.sizeIcon}></ScheduleIcon>
                                <Text className={classes.paddingLeft} size={10}>
                                  {' '}
                                  {/* {dayjs(createdAt).format('DD MMM')}{' '} */}
                                  {formatDateByLang(createdAt || '', i18n.language)}{' '}
                                </Text>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    }
                    return null
                  }),
                )}
              </div>
              <div className={classes.positionDetail}></div>
              <div className={classes.positionTextHead}>
                <Text weight={300}>{t('notification.recently')}</Text>
              </div>

              {noti?.pages.map((noti1, index) =>
                noti1?.bottom.items.map(({ notification, isRead }, i) => {
                  var color = WHITE_FFFFFF
                  if (isRead === false) {
                    color = GRAY_BEBEBE
                  } else {
                    color = WHITE_FFFFFF
                  }
                  const {
                    title = '{}',
                    description = '{}',
                    createdAt,
                    meta = '{}',
                    id,
                  } = notification || {}
                  var titleLang = JSON.parse(title)
                  var descripionLang = JSON.parse(description)
                  var metaLink = JSON.parse(meta)
                  var titleShow = ''
                  var descriptionShow = ''
                  if (i18n.language === 'en') {
                    titleShow = titleLang.en
                    descriptionShow = descripionLang.en
                  } else if (i18n.language === 'th') {
                    titleShow = titleLang.th
                    descriptionShow = descripionLang.th
                  }

                  return (
                    <div>
                      <Link
                        to={`/${metaLink.name}`}
                        className={classes.textDecoration}
                        onClick={() => readNotification(id!)}
                      >
                        <div className={classes.positionDetail} style={{ background: color }}>
                          <Text size={17}> {titleShow} </Text>
                          <Text weight={300} size={15}>
                            {descriptionShow}
                          </Text>
                          <div className={classes.dateTime}>
                            <ScheduleIcon className={classes.sizeIcon}></ScheduleIcon>
                            <Text className={classes.paddingLeft} size={10}>
                              {/* {dayjs(createdAt).format('DD MMM')} */}
                              {formatDateByLang(createdAt || '', i18n.language)}{' '}
                            </Text>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                }),
              )}
              <div className={classes.positionButton}>
                <Button onClick={() => fetchNextPage()} disabled={!hasNextPage}>
                  <Text>{t('notification.more')}</Text>
                </Button>

                {/* <div style={{ padding: '0.625rem' }}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    className={classes.buttonSize}
                  >
                    <ToggleButton
                      value="left"
                      style={{ background: colorBackgroundChange(alignment, 'left') }}
                    >
                      <Text color={colorTextChange(alignment, 'left')}>EN</Text>
                    </ToggleButton>
                    <ToggleButton
                      value="right"
                      style={{ background: colorBackgroundChange(alignment, 'right') }}
                    >
                      <Text color={colorTextChange(alignment, 'right')}>TH</Text>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div> */}
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </>
  )
}
export default NotificationPopover
