import { useQuery, UseQueryOptions } from 'react-query'
import { api } from '../../utils/api'
import { GetCurrentApprover } from './idp-planning-type'

export const IDP_PLANNINGS_APPROVER = 'idp-planning-approvers'

export const useCurrentIdpPlanningApprover = (
  idpPlanningId?: string,
  option?: UseQueryOptions<GetCurrentApprover>,
) => {
  return useQuery<GetCurrentApprover>(
    [IDP_PLANNINGS_APPROVER, 'current-approver', { idpPlanningId }],
    async () => {
      const response = await api.blcpIdp.get<{ activeApprover: GetCurrentApprover }>(
        `${IDP_PLANNINGS_APPROVER}/current-approver`,
        { idpPlanningId },
      )
      return response.data.activeApprover
    },
    {
      enabled: !!idpPlanningId,
      ...option,
    },
  )
}
