import { useCallback, useMemo } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Text from '../common/Text'
import { BLUE_004D99, WHITE_FFFFFF } from '../../constant/colors'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

export const initLangKey = 'blcp-lang'

const useStyles = makeStyles({
  buttonSize: {
    width: 'max-content',
    height: '2rem',
  },
})

const SwitchLanguageButton = () => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  const currentLang = useMemo(() => {
    return i18n.language
  }, [i18n.language])

  const handleChangeLang = useCallback(
    (event: React.MouseEvent<HTMLElement>, language: string | null) => {
      if (language !== null) {
        i18n.changeLanguage(language)
        i18next.reloadResources()
        localStorage.setItem(initLangKey, language)
      }
    },
    [i18n],
  )

  return (
    <ToggleButtonGroup
      value={currentLang}
      exclusive
      onChange={handleChangeLang}
      className={classes.buttonSize}
    >
      <ToggleButton value="en" style={{ background: currentLang === 'en' ? BLUE_004D99 : '' }}>
        <Text color={currentLang === 'en' ? WHITE_FFFFFF : ''}>EN</Text>
      </ToggleButton>
      <ToggleButton value="th" style={{ background: currentLang === 'th' ? BLUE_004D99 : '' }}>
        <Text color={currentLang === 'th' ? WHITE_FFFFFF : ''}>TH</Text>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default SwitchLanguageButton
