import { AxiosRequestConfig } from 'axios'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { useOnError } from '../../App'
import { api } from '../../utils/api'
import { useSnackbar } from '../../utils/custom-hook'
import { downloadFile } from '../../utils/helper'
import { Category, useTrackingEvent } from '../../utils/tracking'
import { CERTIFICATE_USER } from '../certificate-user/certificate-user-query'
import { CERTIFICATES, USERS } from '../user/user-query'
import {
  ExportTrainingRecordUsersParams,
  GetTrainingRecordUsersParams,
  GetTrainingRecordUsersResponse,
  UpdateCertificateUsersParams,
} from './training-record-user-types'

export const TRAINING_RECORD_USERS = `training-record-users`
export const EXPORT_TRAINING_RECORD_USERS = `${TRAINING_RECORD_USERS}/export`

const emptyResponse: GetTrainingRecordUsersResponse = {
  items: [],
  meta: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 5,
    totalItems: 0,
    totalPages: 1,
  },
}

export const useGetTrainingRecordUsers = (params: GetTrainingRecordUsersParams) => {
  const { templateId } = params
  return useQuery(
    [TRAINING_RECORD_USERS, params],
    async () => {
      if (!templateId) return Promise.resolve(emptyResponse)
      const { data } = await api.blcpIdp.get<GetTrainingRecordUsersResponse>(
        TRAINING_RECORD_USERS,
        params,
      )
      return data
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetTrainingRecordUsersInfinite = (params: GetTrainingRecordUsersParams) => {
  const { templateId, userId, page = 1, limit = 5, q } = params
  return useInfiniteQuery(
    [TRAINING_RECORD_USERS, 'infinite', params],
    async ({ pageParam = page }) => {
      const { data } = await api.blcpIdp.get<GetTrainingRecordUsersResponse>(
        TRAINING_RECORD_USERS,
        {
          q,
          templateId,
          userId,
          page: pageParam,
          limit,
        },
      )
      return data
    },
    {
      getNextPageParam: ({ meta }) => {
        return meta.currentPage < meta.totalPages ? meta.currentPage + 1 : null
      },
      refetchOnWindowFocus: false,
      enabled: !!templateId,
    },
  )
}

export const useUpdateTrainingRecordUserCertificates = (userId: string) => {
  const { t } = useTranslation()
  const trackingEvent = useTrackingEvent()
  const queryClient = useQueryClient()
  const { snackbar } = useSnackbar()
  return useMutation(
    async (params: UpdateCertificateUsersParams) => {
      trackingEvent(Category.MyCapability, 'Update user training record')
      const { trainingRecordUserId, certificateUserIds } = params
      const { data } = await api.blcpIdp.patch(
        `${TRAINING_RECORD_USERS}/${trainingRecordUserId}/update-certificates`,
        { certificateUserIds },
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CERTIFICATE_USER])
        queryClient.invalidateQueries([USERS, userId, CERTIFICATES])
        queryClient.invalidateQueries([TRAINING_RECORD_USERS])
        snackbar({
          type: 'success',
          message: t('status.success'),
        })
      },
    },
  )
}

export const useExportTrainingRecordUsers = (option?: AxiosRequestConfig) => {
  const trackingEvent = useTrackingEvent()
  const { snackbar } = useSnackbar()
  const onError = useOnError()
  const { t } = useTranslation('backend')
  const { t: localTrans } = useTranslation()

  return useMutation<unknown, ApiError, ExportTrainingRecordUsersParams>(
    async (params) => {
      trackingEvent(Category.MyCapability, 'Download user training record')
      const res = await api.blcpIdp.getFile(EXPORT_TRAINING_RECORD_USERS, params, option)
      downloadFile(res)
    },
    {
      onSuccess: () => {
        snackbar({
          type: 'success',
          message: localTrans('status.success'),
        })
      },
      onError: (error) => {
        const { message = 'Not Identify', meta = {} } = error || {}
        const { param = {} } = meta
        if (message === '422-028') {
          snackbar({
            type: 'success',
            message: t(message, { ...param, defaultValue: message }),
          })
        } else {
          onError(error)
        }
      },
    },
  )
}
