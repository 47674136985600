import { useMutation } from 'react-query'
import { api } from '../../utils/api'
import { downloadFile } from '../../utils/helper'
import { Category, useTrackingEvent } from '../../utils/tracking'

export const DOCUMENT_URL = 'documents'
export const MANUAL_URL = `${DOCUMENT_URL}/manual`

export const useGetManual = () => {
  const trackingEvent = useTrackingEvent()
  return useMutation([MANUAL_URL], async () => {
    trackingEvent(Category.Other, 'Download Manual')
    const res = await api.blcpIdp.getFile(MANUAL_URL)
    downloadFile(res)
  })
}
